import React, { useEffect, useState } from "react";
import { parse, format, isValid } from "date-fns";
import ImageSlider from "../../components/Listings/ImageSlider";
import { useNavigate } from "react-router-dom";
import {
  doc,
  getDocs,
  collection,
  updateDoc,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase/index";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import SubleaseFilters from "./SubleaseFilters";

function SubleasePage() {
  const navigate = useNavigate();
  const [subleases, setSubleases] = useState([]);
  const [userSubleases, setUserSubleases] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [subleaseToDelete, setSubleaseToDelete] = useState(null);
  const [filters, setFilters] = useState({
    priceRange: [400, 2000],
    dateRange: {
      start: null,
      end: null
    },
    bedRange: [0, 5],
    bathRange: [1, 4],
    quickDateFilter: 'all'
  });

  const onPostSublease = () => {
    navigate("/sublease-intro");
  };

  const fetchUserSubleases = async () => {
    try {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userSubleaseIds = userDoc.data().subleases || [];
        const userSubleasesData = await Promise.all(
          userSubleaseIds.map(async (subleaseId) => {
            const subleaseDocRef = doc(db, "subleases", subleaseId);
            const subleaseDoc = await getDoc(subleaseDocRef);
            return { id: subleaseDoc.id, ...subleaseDoc.data() };
          })
        );
        setUserSubleases(userSubleasesData);
      }
    } catch (error) {
      console.error("Error fetching user's subleases:", error);
    }
  };

  const fetchSubleases = async () => {
    try {
      const subleasesCollectionRef = collection(db, "subleases");
      const subleasesSnapshot = await getDocs(subleasesCollectionRef);
      const subleasesData = subleasesSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((sublease) => sublease.showOnWebsite);

      // console.log(subleasesData);
      setSubleases(subleasesData);
    } catch (error) {
      console.error("Error fetching subleases:", error);
    }
  };

  useEffect(() => {
    fetchUserSubleases();
    fetchSubleases();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) {
      console.error("Invalid date string: ", dateString);
      return "Invalid Date";
    }
  
    const parsedDate = parse(dateString, "MM-dd-yyyy", new Date());
    if (!isValid(parsedDate)) {
      console.error("Parsed date is invalid: ", parsedDate);
      return "Invalid Date";
    }
  
    return format(parsedDate, "M/d/yy");
  };
  

  const handleNavigate = (id) => {
    navigate(`/sublease/${id}`);
  };

  const handleDeleteClick = (subleaseId) => {
    setSubleaseToDelete(subleaseId);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (!subleaseToDelete) return;

    
    try {
      const userId = auth.currentUser.uid;

      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        subleases: arrayRemove(subleaseToDelete),
      });

      const subleaseDocRef = doc(db, "subleases", subleaseToDelete);
      await updateDoc(subleaseDocRef, {
        showOnWebsite: false,
      });

      setShowModal(false);
      fetchUserSubleases();
      fetchSubleases();
    } catch (error) {
      console.error("Error deleting sublease:", error);
      setShowModal(false);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setSubleaseToDelete(null);
  };

  const calculateQualityScore = (sublease, hostProfile) => {
    let score = 0;
    
    // Image quality scoring (0-30 points)
    if (sublease.images && Array.isArray(sublease.images)) {
      score += Math.min(sublease.images.length * 10, 10); // 6 points per image, max 30
    }

    // Host profile quality scoring (0-30 points)
    if (hostProfile) {
      // Profile picture exists
      if (hostProfile.profilePicture) score += 15;
      
      // Contact information completeness
      // if (hostProfile.contact?.email) score += 5;
      // if (hostProfile.contact?.phoneNumber) score += 5;
      // if (hostProfile.contact?.instagramHandle) score += 5;
    }

    // Description quality scoring (0-20 points)
    // if (sublease.description) {
    //   const wordCount = sublease.description.split(/\s+/).length;
    //   score += Math.min(wordCount / 5, 20); // 1 point per 5 words, max 20 points
    // }

    // Roommate information scoring (0-10 points)
    // if (sublease.roommates && Array.isArray(sublease.roommates)) {
    //   score += Math.min(sublease.roommates.length * 5, 10);
    // }

    // Fee transparency scoring (0-10 points)
    // if (sublease.feeInfo) score += 10;

    return score;
  };

  const filterSubleases = (subleases) => {
    const scoredSubleases = subleases.filter(sublease => {
      // Apply existing filters
      const meetsPrice = sublease.rent >= filters.priceRange[0] && 
                        sublease.rent <= filters.priceRange[1];
      const meetsBeds = sublease.bedrooms >= filters.bedRange[0] && 
                       sublease.bedrooms <= filters.bedRange[1];
      const meetsBaths = sublease.bathrooms >= filters.bathRange[0] && 
                        sublease.bathrooms <= filters.bathRange[1];

      // Date filtering logic
      let meetsDate = true;
      let overlapScore = 0;
      
      if (filters.quickDateFilter !== 'all') {
        // Reference existing date filtering logic
        // Lines 156-197 in original code
      }

      sublease.overlapScore = overlapScore;
      return meetsPrice && meetsBeds && meetsBaths && meetsDate;
    }).map(sublease => {
      // Calculate quality score
      const qualityScore = calculateQualityScore(sublease, sublease.hostProfile);
      
      // Normalize overlap score (0-100)
      const normalizedOverlapScore = sublease.overlapScore ? 
        Math.min(sublease.overlapScore / (30 * 24 * 60 * 60 * 1000) * 100, 100) : 0;

      // Combined score (70% quality, 30% overlap)
      const combinedScore = (qualityScore * 0.7) + (normalizedOverlapScore * 0.3);
      
      return {
        ...sublease,
        qualityScore,
        combinedScore
      };
    });

    // Sort by combined score (highest first)
    return scoredSubleases.sort((a, b) => b.qualityScore - a.qualityScore);
  };

  return (
    <div className="relative">

      {showModal && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
          style={{ zIndex: 2 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-medium">
              Are you sure you want to delete this sublease?
            </h3>
            <div className="mt-4 flex justify-end">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
              >
                Delete
              </button>
              <button
                onClick={cancelDelete}
                className="bg-gray-300 text-black px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mx-auto p-4 md:px-16">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-medium py-4">Short term stays</h1>
          <div className="flex items-center gap-4">
            {userSubleases && userSubleases.length > 0 && (
              <span className="text-sm">
                Your listing:{' '}
                <span
                  className="font-medium text-blue-600 hover:underline cursor-pointer"
                  onClick={() => handleNavigate(userSubleases[0].id)}
                >
                  {userSubleases[0].apartmentName}
                </span>
              </span>
            )}
            <button
              className="text-sm font-medium underline"
              onClick={onPostSublease}
            >
              List your apartment
            </button>
          </div>
        </div>

        {/* {userSubleases && userSubleases.length > 0 && (
          <div className="mb-4">
            <ul>
              {userSubleases.map((sublease) => (
                <li
                  key={sublease.id}
                  className="flex justify-between items-center p-2 border rounded-md shadow-sm"
                >
                  <div className="flex-grow">
                    <span
                      className="font-medium text-blue-600 hover:underline cursor-pointer"
                      onClick={() => handleNavigate(sublease.id)}
                    >
                      {sublease.apartmentName}
                    </span>
                    {" • "}${sublease.rent}/mo • {sublease.configuration} •{" "}
                    {sublease.bedrooms} beds • {sublease.bathrooms} bath •{" "}
                    {formatDate(sublease.startDate)} to{" "}
                    {formatDate(sublease.endDate)}
                  </div>
                  <button
                    className="text-red-500 ml-4"
                    onClick={() => handleDeleteClick(sublease.id)}
                  >
                    Delete Sublease
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )} */}

        <SubleaseFilters filters={filters} setFilters={setFilters} />

        <div
          className="grid gap-y-8 gap-x-8"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          }}
        >
          {filterSubleases(subleases).map((sublease) => (
            <SubleaseListingCard key={sublease.id} sublease={sublease} />
          ))}
        </div>
      </div>
    </div>
  );
}

function SubleaseListingCard({ sublease }) {
  const navigate = useNavigate();
  const id = sublease.id;
  const beds = sublease.bedrooms;
  const baths = sublease.bathrooms;
  const startDate = sublease.startDate;
  const endDate = sublease.endDate;
  const rent = sublease.rent;
  const [imageUrls, setImageUrls] = useState([]);
  const [hostProfile, setHostProfile] = useState(null);

  useEffect(() => {
    const fetchHostProfile = async () => {
      if (sublease.userId) {
        const docRef = doc(db, "users", sublease.userId);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setHostProfile(docSnap.data());
          }
        } catch (error) {
          console.error("Error fetching host profile:", error);
        }
      }
    };

    fetchHostProfile();
  }, [sublease.userId]);

  useEffect(() => {
    const fetchImages = async () => {
      if (sublease.images && Array.isArray(sublease.images)) {
        const orderedUrls = sublease.images.map(img => img.url);
        setImageUrls(orderedUrls);
      } else {
        const storage = getStorage();
        const listRef = ref(storage, `sublease_images/${id}/`);
        try {
          const res = await listAll(listRef);
          const urls = await Promise.all(
            res.items.map((itemRef) => getDownloadURL(itemRef))
          );
          setImageUrls(urls);
        } catch (error) {
          console.error("Error fetching images: ", error);
        }
      }
    };

    fetchImages();
  }, [id, sublease]);

  const onImageClick = (e) => {
    e.preventDefault();
    navigate(`/sublease/${id}`);
    // console.log("Listing clicked");
  };

  const handleFavClick = () => {
    // console.log(id);
    // console.log("Fav clicked");
  };

  const parseDate = (dateString) => {
    const parsedDate = parse(dateString, "MM-dd-yyyy", new Date());
    const formattedDate = format(parsedDate, "MMM d");
    return formattedDate;
  };

  return (
    <div className="relative">
      <div className="absolute top-0 right-0 z-10">
        <button onClick={handleFavClick}>
          <div className="p-2 m-2">
            <i className="fa-regular fa-heart fa-lg hover:scale-125 text-white"></i>
          </div>
        </button>
      </div>

      <div key={sublease.id}>
        <div className="relative flex flex-col rounded-md overflow-hidden">
          <div className="w-full overflow-hidden relative">
            <ImageSlider
              images={imageUrls}
              onImageClick={onImageClick}
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </div>
          <div className="cursor-pointer py-1" onClick={onImageClick}>
            <div className="flex justify-between">
              <div className="flex flex-col w-full">
                <div className="flex justify-between items-center">
                  <div className="text-md font-medium text-black truncate">
                    {sublease.apartmentName}
                  </div>
                  {hostProfile && hostProfile.profilePicture && (
                    <img
                      src={hostProfile.profilePicture}
                      alt="Host"
                      className="w-8 h-8 rounded-full object-cover ml-2"
                    />
                  )}
                </div>
                <div className="text-sm font-normal text-gray-600">
                  {parseDate(startDate)} - {parseDate(endDate)}
                </div>
                <div className="text-sm font-normal text-gray-600">
                  {beds} beds • {baths} bath
                </div>
              </div>
            </div>

            <div className="flex pt-2">
              <div className="text-sm font-semibold text-black">${rent}</div>
              <div className="text-sm text-black ml-1">month</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubleasePage;
