import React, { useState, useEffect, lazy, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchUserData } from "../backend/dbAPI";
import {
  preferenceDescriptions,
  lifestylePreferenceDescriptions
} from "../data/consts/prefrencesDesc";
import MainHeader from "../components/Headers/MainHeader";
import { fetchLeanSpecificApartment } from "../backend/dynamoAPI";
import ListingCard from "../components/Listings/ListingCard";

const MessageModal = lazy(() => import("../components/Roommates/MessageModal"));

// const testRoommateAptids = [
//   {
//     name: "Here Champaign",
//     img: "https://irp.cdn-website.com/cbe2c725/dms3rep/multi/564a62b3cefb6809.jpg",
//   },
//   {
//     name: "309 Green",
//     img: "https://www.americancampus.com/getmedia/f5a620e3-ce1a-49b4-845b-e6d3619a4c5b/416_-01_Gallery_730x547.jpg",
//   },
//   {
//     name: "75 E Armory",
//     img: "https://ugroupcu.com/property_images/property/detailsimg/4b24d9eeabd0a212788fd5624cefda7d75-Armory-Exterior-091.jpg",
//   },
// ];

function RoommateDetailPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const [lifestyleModalOpen, setLifestyleModalOpen] = useState(false);
  const [livingHabitsModalOpen, setLivingHabitsModalOpen] = useState(false);

  const [favApartments, setFavApartments] = useState([]);

  const getTopSimilarPreferencesDesc = (userPrefs, roommatePrefs, desc) => {
    const similarPrefs = [];
    Object.keys(userPrefs).forEach((key) => {
      if (desc[key]) {
        if (userPrefs[key] === roommatePrefs[key]) {
          similarPrefs.push({
            category: key,
            description: desc[key](userPrefs[key])
          });
        }
      }
    });
    return similarPrefs.slice(0, 4);
  };

  const similarLifestyles = getTopSimilarPreferencesDesc(
    userData?.lifestylePreferences || {},
    userData?.lifestylePreferences || {},
    lifestylePreferenceDescriptions
  );

  const similarLivingHabits = getTopSimilarPreferencesDesc(
    userData?.livingHabits || {},
    userData?.livingHabits || {},
    preferenceDescriptions
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setLoading(true);
        const data = await fetchUserData(userId);
        if (!data) {
          setError({
            type: "general",
            message: "User not found"
          });
          return;
        }
        setUserData(data);

        // Check if the user has favorite apartments and fetch those apartments
        if (data.favorites && data.favorites.length > 0) {
          const favoriteApartmentsPromises = data.favorites.map(async (favId) => {
            let groupId = "single_apartments";
            let apartmentId = favId;

            if (apartmentId.includes("/")) {
              const [groupKey, id] = apartmentId.split("/");
              groupId = groupKey;
              apartmentId = favId;
            }

            return await fetchLeanSpecificApartment(groupId, apartmentId);
          });

          const favoriteApartments = await Promise.all(favoriteApartmentsPromises);
          setFavApartments(favoriteApartments);
        }
      } catch (err) {
        // Always treat unauthorized or authentication errors as requiring login
        if (err.message === "Unauthorized" || 
            err.code === "auth/not-authenticated" || 
            err.message.includes("not authenticated")) {
          setError({
            type: "auth",
            message: "Please sign in with your student account to view profiles"
          });
        } else {
          setError({
            type: "auth", // Changed to always require auth for any error
            message: "Please sign in with your student account to view profiles"
          });
        }
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      loadUserData();
    } else {
      setError({
        type: "auth", // Changed to auth type
        message: "Please sign in with your student account to view profiles"
      });
      setLoading(false);
    }
  }, [userId]);
  

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader">Loading...</div>{" "}
        {/* Tailwind doesn't have a loader by default, customize as needed */}
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
        <div className="text-center p-8 bg-white rounded-lg shadow-sm max-w-md">
          <h2 className="text-2xl font-bold mb-4">
            {error.type === "auth" ? "Student Access Required" : "Error"}
          </h2>
          <p className="text-gray-600 mb-6">{error.message}</p>
          {error.type === "auth" && (
            <button
              onClick={() => navigate("/login", { state: { from: window.location.pathname } })}
              className="bg-logo_blue hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition-colors duration-200"
            >
              Sign In
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <MainHeader />
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row gap-8 px-4 py-8">
        {/* Left side: Profile Card */}
        <div className="w-full md:w-1/3">
          <div className="bg-white rounded-2xl shadow-sm hover:shadow-xl transition-shadow duration-300 border border-gray-100 overflow-hidden">
            <ProfileCard userData={userData} />
            <div className="px-6 py-6">
              <button
                onClick={handleOpen}
                className="w-full bg-logo_blue hover:bg-blue-600 text-white py-3 rounded-xl font-medium transition-colors duration-200 flex items-center justify-center space-x-2"
              >
                <span>Message {userData.firstName}</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Right side: Scrollable content */}
        <div className="w-full md:w-2/3 space-y-6">
          {/* Interests Section */}
          {userData.interests && Object.keys(userData.interests).length > 0 && (
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-xl transition-shadow duration-300 border border-gray-100">
              <h3 className="text-xl font-semibold mb-4">Interests</h3>
              <ProfileInterests selectedInterests={userData.interests} />
              {userData.shows && (
                <div className="mt-4 pt-4 border-t border-gray-100">
                  <span className="font-medium">Currently Watching: </span>
                  <span className="text-gray-700">{userData.shows}</span>
                </div>
              )}
            </div>
          )}

          {/* Interested Apartments Section */}
          {favApartments.length > 0 && (
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-xl transition-shadow duration-300 border border-gray-100">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Interested Apartments</h2>
                <button
                  className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  onClick={() => {
                    const container = document.getElementById("scrollableContainer");
                    container.scrollBy({ left: 300, behavior: "smooth" });
                  }}
                >
                  <i className="fas fa-chevron-right text-gray-600"></i>
                </button>
              </div>
              <div
                id="scrollableContainer"
                className="flex space-x-4 overflow-x-auto pb-4 scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-300 scrollbar-track-transparent"
              >
                {favApartments.map((apartmentId) => (
                  <div key={apartmentId.id} className="flex-shrink-0 w-64">
                    <ListingCard apartment={apartmentId} />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Living Habits Section */}
          {userData.livingHabits && (
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-xl transition-shadow duration-300 border border-gray-100">
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-2">
                  <h3 className="text-xl font-semibold">Living Habits</h3>
                  <span className="text-sm px-2 py-1 bg-green-100 text-green-800 rounded-full">90% Match</span>
                </div>
                <button
                  onClick={() => setLivingHabitsModalOpen(true)}
                  className="text-logo_blue hover:text-blue-600 text-sm font-medium"
                >
                  View All
                </button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                {similarLivingHabits.map((habit, index) => (
                  <div 
                    key={index} 
                    className="flex items-center p-2.5 rounded-lg border border-gray-100 hover:border-blue-200 transition-all duration-200"
                  >
                    <div className="mr-3">
                      <div className="w-2 h-2 rounded-full bg-green-500"></div>
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="text-xs text-gray-500 uppercase tracking-wide font-medium truncate">
                        {habit.category}
                      </div>
                      <div className="text-sm font-medium text-gray-900 truncate">
                        {habit.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Lifestyle Section */}
          {userData.lifestylePreferences && (
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-xl transition-shadow duration-300 border border-gray-100">
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-2">
                  <h3 className="text-xl font-semibold">Lifestyle</h3>
                  <span className="text-sm px-2 py-1 bg-blue-100 text-blue-800 rounded-full">85% Match</span>
                </div>
                <button
                  onClick={() => setLifestyleModalOpen(true)}
                  className="text-logo_blue hover:text-blue-600 text-sm font-medium"
                >
                  View All
                </button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                {similarLifestyles.map((lifestyle, index) => (
                  <div 
                    key={index} 
                    className="flex items-center p-2.5 rounded-lg border border-gray-100 hover:border-blue-200 transition-all duration-200"
                  >
                    <div className="mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="text-xs text-gray-500 uppercase tracking-wide font-medium truncate">
                        {lifestyle.category}
                      </div>
                      <div className="text-sm font-medium text-gray-900 truncate">
                        {lifestyle.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

               {/* Additional Info Section */}
               <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-xl transition-shadow duration-300 border border-gray-100">
            <h3 className="text-xl font-semibold mb-4">Additional Info</h3>
            <div className="space-y-2">
              {userData.ethnicity && (
                <div className="flex items-center space-x-2">
                  <span className="text-gray-600">Ethnicity:</span>
                  <span className="font-medium">{userData.ethnicity}</span>
                </div>
              )}
              {userData.homeState && (
                <div className="flex items-center space-x-2">
                  <span className="text-gray-600">Home State:</span>
                  <span className="font-medium">{userData.homeState}</span>
                </div>
              )}
              {userData.isInternational === "Yes" && (
                <div className="inline-flex items-center px-3 py-1 rounded-full bg-blue-50 text-blue-700">
                  <span>International Student</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* MessageModal is lazily loaded and shown when 'Connect' is clicked */}
      <Suspense fallback={<div>Loading modal...</div>}>
        {open && (
          <MessageModal
            userData={userData}
            isOpen={open}
            onClose={handleClose}
          />
        )}
      </Suspense>

      {/* Add the modals */}
      <LifestyleModal 
        isOpen={lifestyleModalOpen}
        onClose={() => setLifestyleModalOpen(false)}
        lifestylePreferences={userData?.lifestylePreferences}
      />
      <LivingHabitsModal
        isOpen={livingHabitsModalOpen}
        onClose={() => setLivingHabitsModalOpen(false)}
        livingHabits={userData?.livingHabits}
      />
    </div>
  );
}

const ProfileCard = ({ userData }) => (
  <div>
    <div className="h-48 bg-gradient-to-r from-blue-500 to-logo_blue"></div>
    <div className="px-6 -mt-20">
      <div className="relative mb-4">
        <img
          alt={`${userData.firstName} ${userData.lastName}`}
          src={userData.profilePicture || "https://via.placeholder.com/150"}
          className="w-32 h-32 rounded-full border-4 border-white object-cover shadow-lg"
        />
      </div>
      <div className="space-y-4">
        <div>
          <h2 className="text-2xl font-bold">{`${userData.firstName} ${userData.lastName}`}</h2>
          <p className="text-gray-600">{`${userData.year.charAt(0).toUpperCase() + userData.year.slice(1).toLowerCase()} in ${userData.major}`}</p>
        </div>

        {userData.instagramHandle && (
          <a
            href={`https://www.instagram.com/${userData.instagramHandle.replace("@", "")}`}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center space-x-2 cursor-pointer"
          >
            <i className="fab fa-instagram text-xl text-pink-500 hover:text-pink-600 transition-colors duration-200"></i>
            <span className="text-pink-500 hover:text-pink-600 hover:underline transition-colors duration-200">
              {userData.instagramHandle.replace("@", "")}
            </span>
          </a>
        )}

        {userData.roommateDescription && (
          <div className="pt-4 border-t border-gray-100">
            <h3 className="font-medium mb-2">About Me</h3>
            <p className="text-gray-700 leading-relaxed">{userData.roommateDescription}</p>
          </div>
        )}

        {userData.priceRange && (
          <div className="inline-flex items-center px-4 py-2 rounded-full bg-green-50 text-green-700">
            <span>${userData.priceRange.lowerLimit} - ${userData.priceRange.upperLimit}</span>
          </div>
        )}
      </div>
    </div>
  </div>
);

const CompactLivingHabits = ({ livingHabits }) => {
  if (!livingHabits) {
    return <></>;
  }

  const habits = [
    {
      question: "Cleanliness",
      value: livingHabits.cleanliness,
      leftText: "A little mess never hurt anybody... 🧹",
      rightText: "Neat freak 🧼",
    },
    {
      question: "Bird Type",
      value: livingHabits.birdType,
      leftText: "Night owl 🦉",
      rightText: "Early bird 🐦",
    },
    {
      question: "Guest Preference",
      value: livingHabits.guestPreference,
      leftText: "Me, myself, and I 🧘",
      rightText: "Friends over 24/7 🎉",
    },
    {
      question: "Noise Level",
      value: livingHabits.noiseLevel,
      leftText: "Quiet Environment 🤫",
      rightText: "Like a lively space 🎶",
    },
    {
      question: "Cooking Frequency",
      value: livingHabits.cookingFrequency,
      leftText: "Eat out every day 🍔",
      rightText: "I'm cheffing in the kitchen 24/7 👨‍🍳",
    },
    {
      question: "Temperature Preference",
      value: livingHabits.temperaturePreference,
      leftText: "Like it cold 🥶",
      rightText: "I want it toasty 🥵",
    },
  ];

  return (
    <div className="bg-white rounded-lg w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4">
        {habits.map((habit, index) => (
          <div key={index} className="mb-4">
            <div className="text-sm font-semibold mb-1">{habit.question}</div>
            <div className="flex items-center justify-between text-sm text-gray-600">
              <span className="pr-2" style={{ width: "40%" }}>
                {habit.leftText}
              </span>
              <div
                className="flex items-center justify-center space-x-2"
                style={{ flex: 1 }}
              >
                {[1, 2, 3, 4].map((value) => (
                  <div
                    key={value}
                    className={`flex items-center justify-center transform transition-transform duration-200 ${
                      habit.value === value
                        ? "bg-blue-600 text-white"
                        : "border-2 border-gray-300 text-gray-500"
                    }`}
                    style={{
                      width: value === 1 || value === 4 ? "20px" : "15px",
                      height: value === 1 || value === 4 ? "20px" : "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                ))}
              </div>
              <span style={{ width: "40%", textAlign: "right" }}>
                {habit.rightText}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const getInterestLevelClass = (level) => {
  switch (level) {
    case 1:
      return "bg-blue-500 text-white";
    case 2:
      return "bg-blue-800 text-white";
    case 3:
      return "bg-logo_blue text-white";
    default:
      return "";
  }
};

const ProfileInterests = ({ selectedInterests }) => {
  const sortedInterests = Object.entries(selectedInterests).sort(
    ([, levelA], [, levelB]) => levelB - levelA
  );

  return (
    <div className="flex flex-wrap">
      {sortedInterests.map(([interest, level]) => (
        <div
          key={interest}
          className={`px-4 py-2 m-1 rounded-lg ${getInterestLevelClass(level)}`}
        >
          {interest}
        </div>
      ))}
    </div>
  );
};

const LifestyleModal = ({ isOpen, onClose, lifestylePreferences }) => {
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Lifestyle Preferences</h2>
          <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="space-y-4">
          {Object.entries(lifestylePreferences).map(([key, value]) => (
            <div key={key} className="border-b pb-3">
              <h3 className="font-medium mb-1">{key}</h3>
              <p className="text-gray-700">{lifestylePreferenceDescriptions[key]?.(value) || value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const LivingHabitsModal = ({ isOpen, onClose, livingHabits }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Living Habits</h2>
          <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <CompactLivingHabits livingHabits={livingHabits} />
      </div>
    </div>
  );
};

export default RoommateDetailPage;
