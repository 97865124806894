import React, { useState } from 'react';
import { format } from 'date-fns';

function SubleaseFilters({ filters, setFilters }) {
  const [activeFilter, setActiveFilter] = useState(null);

  const quickDateOptions = [
    { label: 'All', value: 'all' },
    { label: 'Summer', value: 'summer' },
    { label: 'Fall', value: 'fall' },
    { label: 'Spring', value: 'spring' },
    { label: 'Whole Year', value: 'wholeYear' }
  ];

  const handlePriceChange = (value) => {
    setFilters(prev => ({...prev, priceRange: value}));
  };

  const handleDateChange = (type, date) => {
    setFilters(prev => ({
      ...prev,
      dateRange: {...prev.dateRange, [type]: date}
    }));
  };

  const handleBedroomChange = (value) => {
    setFilters(prev => ({...prev, bedRange: value}));
  };

  const handleBathroomChange = (value) => {
    setFilters(prev => ({...prev, bathRange: value}));
  };

  const handleQuickDateSelect = (value) => {
    if (value === 'all') {
      setFilters(prev => ({
        ...prev,
        quickDateFilter: 'all',
        dateRange: { start: null, end: null }
      }));
    } else {
      setFilters(prev => ({
        ...prev,
        quickDateFilter: prev.quickDateFilter === value ? null : value,
        dateRange: { start: null, end: null }
      }));
    }
  };

  return (
    <div className="w-full mb-8">
      <div className="flex flex-wrap gap-4">
        {/* Date Range Filter */}
        <div className="relative flex-1 min-w-[240px]">
          <button
            onClick={() => {
              setActiveFilter(activeFilter === 'date' ? null : 'date');
            }}
            className="w-full px-6 py-3 bg-white border border-gray-200 rounded-lg hover:border-gray-300 transition-all"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">Dates</p>
                <p className="text-sm font-semibold">
                  {filters.quickDateFilter === 'all' ? 
                    'All dates' :
                    filters.quickDateFilter ? 
                      quickDateOptions.find(opt => opt.value === filters.quickDateFilter)?.label :
                      filters.dateRange.start && filters.dateRange.end ?
                        `${format(new Date(filters.dateRange.start), 'MMM d')} - ${format(new Date(filters.dateRange.end), 'MMM d')}` :
                        'Add dates'}
                </p>
              </div>
              <i className="fas fa-calendar-alt text-gray-400"></i>
            </div>
          </button>
          {activeFilter === 'date' && (
            <div className="absolute top-full left-0 mt-2 w-full bg-white rounded-lg shadow-xl border border-gray-100 p-4 z-50">
              <div className="space-y-4">
                {/* Quick Date Options */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Common Periods</label>
                  <div className="flex flex-wrap gap-2">
                    {quickDateOptions.map(option => (
                      <button
                        key={option.value}
                        onClick={() => handleQuickDateSelect(option.value)}
                        className={`px-3 py-1.5 rounded-full text-sm font-medium transition-all
                          ${filters.quickDateFilter === option.value
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          }`}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="border-t border-gray-100 pt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Custom Dates</label>
                  <div className="space-y-2">
                    <input
                      type="date"
                      value={filters.dateRange.start || ''}
                      onChange={(e) => handleDateChange('start', e.target.value)}
                      className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <input
                      type="date"
                      value={filters.dateRange.end || ''}
                      onChange={(e) => handleDateChange('end', e.target.value)}
                      className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Beds & Baths Filter */}
        <div className="relative flex-1 min-w-[240px]">
          <button
            onClick={() => setActiveFilter(activeFilter === 'rooms' ? null : 'rooms')}
            className="w-full px-6 py-3 bg-white border border-gray-200 rounded-lg hover:border-gray-300 transition-all"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">Rooms</p>
                <p className="text-sm font-semibold">
                  {`${filters.bedRange[0] === 0 && filters.bedRange[1] === 5 ? 'All' : `${filters.bedRange[0]}-${filters.bedRange[1]}`} beds • 
                    ${filters.bathRange[0] === 1 && filters.bathRange[1] === 4 ? 'All' : `${filters.bathRange[0]}-${filters.bathRange[1]}`} baths`}
                </p>
              </div>
              <i className="fas fa-bed text-gray-400"></i>
            </div>
          </button>
          {activeFilter === 'rooms' && (
            <div className="absolute top-full left-0 mt-2 w-full bg-white rounded-lg shadow-xl border border-gray-100 p-4 z-50">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Bedrooms</label>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleBedroomChange([0, 5])}
                      className={`px-4 py-2 rounded-lg text-sm font-medium ${
                        filters.bedRange[0] === 0 && filters.bedRange[1] === 5
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      All
                    </button>
                    {[0, 1, 2, 3, 4, 5].map((num) => (
                      <button
                        key={num}
                        onClick={() => handleBedroomChange([num, num])}
                        className={`px-4 py-2 rounded-lg text-sm font-medium ${
                          filters.bedRange[0] === num && filters.bedRange[1] === num
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                      >
                        {num === 0 ? 'Studio' : num}
                      </button>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Bathrooms</label>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleBathroomChange([1, 4])}
                      className={`px-4 py-2 rounded-lg text-sm font-medium ${
                        filters.bathRange[0] === 1 && filters.bathRange[1] === 4
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      All
                    </button>
                    {[1, 2, 3, 4].map((num) => (
                      <button
                        key={num}
                        onClick={() => handleBathroomChange([num, num])}
                        className={`px-4 py-2 rounded-lg text-sm font-medium ${
                          filters.bathRange[0] === num && filters.bathRange[1] === num
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                      >
                        {num}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Price Range Filter */}
        <div className="relative flex-1 min-w-[240px]">
          <button
            onClick={() => setActiveFilter(activeFilter === 'price' ? null : 'price')}
            className="w-full px-6 py-3 bg-white border border-gray-200 rounded-lg hover:border-gray-300 transition-all"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">Price</p>
                <p className="text-sm font-semibold">
                  ${filters.priceRange[0]} - ${filters.priceRange[1]}
                </p>
              </div>
              <i className="fas fa-dollar-sign text-gray-400"></i>
            </div>
          </button>
          {activeFilter === 'price' && (
            <div className="absolute top-full left-0 mt-2 w-full bg-white rounded-lg shadow-xl border border-gray-100 p-4 z-50">
              <div className="space-y-4">
                <div className="flex gap-2">
                  {[500, 1000, 1500, 2000].map((price) => (
                    <button
                      key={price}
                      onClick={() => handlePriceChange([0, price])}
                      className="px-4 py-2 rounded-lg text-sm font-medium bg-gray-100 text-gray-700 hover:bg-gray-200"
                    >
                      Under ${price}
                    </button>
                  ))}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Price Range</label>
                  <div className="flex gap-4 items-center">
                    <input
                      type="number"
                      value={filters.priceRange[0]}
                      onChange={(e) => handlePriceChange([parseInt(e.target.value), filters.priceRange[1]])}
                      className="w-full p-2 border border-gray-200 rounded-lg"
                      placeholder="Min"
                    />
                    <span className="text-gray-500">to</span>
                    <input
                      type="number"
                      value={filters.priceRange[1]}
                      onChange={(e) => handlePriceChange([filters.priceRange[0], parseInt(e.target.value)])}
                      className="w-full p-2 border border-gray-200 rounded-lg"
                      placeholder="Max"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubleaseFilters;