import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase/index"; // Make sure to import auth from Firebase
import { getDoc, doc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth"; // Import auth state change listener

function UserCards({ apartmentId = "general", filters, load = true }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null); // Store current user ID
  const navigate = useNavigate();

  // Listen for the current authenticated user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserId(user.uid); // Set current user ID if logged in
      } else {
        setCurrentUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (load && currentUserId) {
      const fetchInterestedUsers = async () => {
        setLoading(true); // Start loading
        try {
          // Step 1: Get the apartment document to fetch userIds
          const apartmentDocRef = doc(db, "apartments", apartmentId);
          const apartmentDoc = await getDoc(apartmentDocRef);

          if (apartmentDoc.exists()) {
            const apartmentData = apartmentDoc.data();
            const userIds = apartmentData.userIds || []; // Get userIds from the apartment document, default to an empty array

            // Step 2: Fetch user information for each userId in the userIds array
            const userPromises = userIds.map((userId) => {
              const userDocRef = doc(db, "users", userId);
              return getDoc(userDocRef).then((userDoc) => ({
                id: userDoc.id,
                ...userDoc.data(),
              }));
            });

            // Resolve all promises and get the list of user data
            const usersList = await Promise.all(userPromises);

            // Step 3: Filter out the current user, users where showRoommateProfile is false, and match filters.gender with user.gender
            const filteredUsers = usersList.filter(
              (user) =>
                user.id !== currentUserId &&
                user.showRoommateProfile &&
                (filters.gender === "all" || user.gender === filters.gender) // If "all", show all users
            );

            const sortedUsers = sortUsersByProfilePicture(filteredUsers);

            // Step 4: Set the filtered list of users
            setUsers(sortedUsers);
          } else {
            console.error("No such apartment document!");
          }
        } catch (error) {
          console.error("Error fetching users:", error);
        }
        setLoading(false); // End loading
      };

      fetchInterestedUsers();
    }
  }, [apartmentId, load, currentUserId, filters.gender]);

  const sortUsersByProfilePicture = (users) => {
    return users.sort((a, b) => {
      if (a.profilePicture && !b.profilePicture) return -1; // a comes before b
      if (!a.profilePicture && b.profilePicture) return 1; // b comes before a
      return 0; // Keep the same order for both having or both lacking profilePicture
    });
  };

  const handleConnectClick = (userId) => {
    // Open the user's profile page in a new tab
    navigate(`/roommate/${userId}`);
  };

  return (
    <div className="bg-gray-50 min-h-screen p-4">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-logo_blue border-t-transparent"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 max-w-7xl mx-auto">
          {users.length > 0 ? (
            users.map((user) => (
              <div
                key={user.id}
                className="bg-white rounded-2xl overflow-hidden shadow-sm hover:shadow-xl transition-shadow duration-300 border border-gray-100"
              >
                {/* Header with profile picture */}
                <div className="relative h-64">
                  <img
                    src={user.profilePicture || "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                    alt={`${user.firstName}'s profile`}
                    className="w-full h-full object-cover object-center"
                  />
                </div>

                {/* User Info Section */}
                <div className="p-6 space-y-3">
                  <div className="text-center">
                    <div className="flex items-center justify-center space-x-2">
                      <h3 className="text-xl font-bold text-gray-900">{user.firstName}</h3>
                      {user.instagramHandle && (
                        <a
                          href={`https://www.instagram.com/${user.instagramHandle.replace("@", "")}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-pink-500 hover:text-pink-600 transition-colors"
                        >
                          <i className="fab fa-instagram text-xl"></i>
                        </a>
                      )}
                    </div>
                    {user.year && user.major && (
                      <p className="text-gray-600 text-sm mt-1">
                        {user.year.charAt(0).toUpperCase() + user.year.slice(1)} • {user.major}
                      </p>
                    )}
                  </div>

                  {/* Price Range Badge */}
                  <div className="flex justify-center">
                    {user.priceRange ? (
                      <span className="bg-green-50 text-green-700 px-3 py-1 rounded-full text-sm">
                        ${user.priceRange.lowerLimit} - ${user.priceRange.upperLimit}
                      </span>
                    ) : (
                      <span className="h-6" />
                    )}
                  </div>

                  {/* Description */}
                  <div className="bg-gray-50 rounded-xl p-4">
                    <p className="text-gray-700 text-sm leading-relaxed line-clamp-4">
                      {user.roommateDescription || "No description provided"}
                    </p>
                  </div>

                  {/* Favorites Section */}
                  <div className="min-h-[36px]">
                    {user.favoriteNames && user.favoriteNames.length > 0 ? (
                      <div>
                        <p className="text-sm font-medium text-gray-700 mb-2">Favorite Properties</p>
                        <div className="flex flex-wrap gap-2">
                          {user.favoriteNames.slice(0, 3).map((name, index) => (
                            <span
                              key={index}
                              className="bg-blue-50 text-blue-600 px-2 py-1 rounded-full text-xs"
                            >
                              {name}
                            </span>
                          ))}
                          {user.favoriteNames.length > 3 && (
                            <span className="text-blue-600 text-xs cursor-pointer hover:underline">
                              +{user.favoriteNames.length - 3} more
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>

                  {/* Connect Button */}
                  <button
                    onClick={() => handleConnectClick(user.id)}
                    className="w-full bg-logo_blue hover:bg-blue-600 text-white py-3 rounded-xl font-medium transition-colors duration-200 flex items-center justify-center space-x-2"
                  >
                    <span>View Full Profile</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-full text-center py-12">
              <div className="text-gray-500">
                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                <p className="mt-2 text-lg font-medium">No users found</p>
                <p className="mt-1">Check back later for potential roommates</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default UserCards;
