import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BetaPage from "./pages/BetaPage";
import SavedPage from "./pages/SavedPage";
import ApartmentDetail from "./pages/ApartmentDetail";
import SubleaseDetail from "./pages/Sublease/SubleaseDetail";
import UIUCApartmentListings from "./pages/UIUCApartmentsPage";
import HomePage from "./pages/HomePage";
import { SignUpPage } from "./components/Auth/signup";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { LoginPage } from "./components/Auth/login";
import FriendsPage from "./pages/FriendsPage";
import GroupsPage from "./pages/GroupsPage";
import InterestedUsers from "./pages/InterestedUsers";
import ProfilePage from "./pages/ProfilePage";
import SubleaseIntroPage from "./pages/Sublease/SubleaseIntroPage";
import SubleaseFormPage from "./pages/Sublease/SubleaseFormPage";
import ApplyIntroPage from "./pages/ApplyIntroPage";
import { AppProvider } from "./AppContext";
import { GuestSignUpPage } from "./components/Auth/guestSignup";
import GroupApartmentDetail from "./pages/GroupApartmentDetail";
import CreateRoommateProfile from "./pages/CreateRoommateProfile";
import RoommateDetailPage from "./pages/RoommateDetailPage";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import QuickSignupPage from "./pages/QuickSignupPage";
import UserSavedPage from "./pages/UserSavedPage";
import TestPage from "./pages/TestPage";
import ApartmentEditor from "./pages/ApartmentEditor";
import BulkEmailSender from "./pages/Email/BulkEmailSender";
import AboutUs from "./pages/AboutUs";
import SubleaseEdit from "./pages/Sublease/SubleaseEdit";

function App() {
  return (
    <AppProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/secret-bulk-email-sender" element={<BulkEmailSender />} />
            <Route path="/secret-apartment-editor" element={<ApartmentEditor />} />
            <Route path="/secret-test" element={<TestPage />} />

            <Route path="/listings" element={<BetaPage />} />

            <Route path="/listing/:id" element={<ApartmentDetail />} />
            <Route
              path="/group-listing/:groupId/:apartmentId"
              element={<GroupApartmentDetail />}
            />
            <Route path="/apply-now/:id/:secondParam?" element={<ApplyIntroPage />} />
            


            <Route path="/interestedusers/:apartmentId/:secondParam?" element={<InterestedUsers />} />

            <Route path="/UIUC" element={<UIUCApartmentListings />} />

            <Route path="/profile/:userId" element={<ProfilePage />} />
            <Route path="/saved" element={<SavedPage />} />
            <Route path="/saved/:userId" element={<UserSavedPage />} />

            <Route
              path="/create-roommate-profile"
              element={<CreateRoommateProfile />}
            />
            <Route
              path="/create-roommate-profile/:aptId"
              element={<CreateRoommateProfile />}
            />
            <Route path="/roommate/:userId" element={<RoommateDetailPage />} />

            <Route path="/sublease/:id" element={<SubleaseDetail />} />
            <Route path="/sublease-intro" element={<SubleaseIntroPage />} />
            <Route path="/sublease-form" element={<SubleaseFormPage />} />
            <Route path="/sublease/edit/:id" element={<SubleaseEdit />} />

            <Route path="/friends" element={<FriendsPage />} />
            <Route path="/groups" element={<GroupsPage />} />

            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/guest-signup" element={<GuestSignUpPage />} />
            <Route path="/login" element={<LoginPage />} />

            <Route path="/quick-signup" element={<QuickSignupPage />} />
          </Routes>
          <Analytics />
          <SpeedInsights />
        </div>
      </Router>
    </AppProvider>
  );
}

export default App;
